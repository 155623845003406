

import {defineComponent} from "vue";

export default defineComponent({
  name: 'CookieConsent',
  components: {},
  data(): {SHD: boolean} {
    return {SHD: true};
  },
  methods: {
    setCookie(name: any, value: any, days: any) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name: any) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    AcceptCookies() {
      this.SHD = false;
      this.setCookie("siD-cAS", "true", 500);
    }
  }

});
