import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import HomeView from '../views/HomeView.vue'
import WantedView from '../views/WantedView.vue';
import LoginView from '../views/LoginView.vue';
import ContactView from '../views/ContactView.vue'
import ImprintView from '../views/ImprintView.vue'
import CreateWanted from '../views/CreateWanted.vue'
import ControlPanelView from "@/views/ControlPanelView.vue";
import ThanksForProviding from "@/views/ThanksForProviding.vue";
import ContactDetailView from "@/views/ContactDetailView.vue";
import AccountDetailView from "@/views/AccountDetailView.vue";
import RegisterView from "@/views/RegisterView.vue";
import PrivacyView from "@/views/PrivacyView.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";
import GeneralContactView from "@/views/GeneralContactView.vue";
import Auctions from "@/views/AuctionView.vue";
import DetailAuctionView from "@/views/DetailAuctionView.vue";
import RegisterTraining from "@/views/RegisterTraining.vue";
import TrainingView from "@/views/TrainingView.vue";
import DetailRecrutingView from "@/views/DetailRecrutingApplyView.vue";
import RecrutingView from "@/views/RecrutingView.vue";
import EmailSave from "@/views/EmailSave.vue";
const routes: Array<RouteRecordRaw> = [
    /**   {
        path: '/',
        name: 'Hauptseite',
        component: HomeView,
    }, **/
    {
        path: '/',
        name: 'Most Wanted',
        component: WantedView,
    },

    {
        path: '/contactgeneral',
        name: 'Allgemeiner Kontakt',
        component: GeneralContactView,
    },
    {
        path: '/contact',
        name: 'Straftäter Kontakt',
        component: ContactView,
    },
    /**{
        path: '/auctions',
        name: 'Auktionen',
        component: Auctions,
    },**/
    /**v  {
        path: '/auction/:id',
        name: 'Auktion',
        component: DetailAuctionView,
    }, **/
    {
        path: '/login',
        name: 'Login',

        component: LoginView,
    },
    {
        path: '/imprint',
        name: 'Impressum',

        component: ImprintView,
    },
    {
        path: '/createwanted',
        name: 'Wanted erstellen',

        component: CreateWanted,
    },
    {
        path: '/cp',
        name: 'Verwaltung',

        component: ControlPanelView,
    },
    {
        path: '/thanksforproviding',
        name: 'thanks',

        component: ThanksForProviding,
    },
    {
        path: '/404',
        name: '404',

        component: NotFoundPage,
    },
    {
        path: '/contactdetail/:id',
        name: 'cst',

        component: ContactDetailView,
    },
    {
        path: '/accountdetail',
        name: 'act',

        component: AccountDetailView,
    },
    {
        path: '/register',
        name: 'Registrierung',

        component: RegisterView,
    },
    /**    {
        path: '/registertraining',
        name: 'Register-Training',
        component: RegisterTraining
    }, **/
    /**    {
        path: '/training/:id',
        name: 'Ausbildung',
        component: TrainingView
    }, **/
  /**  {
        path: "/recruting",
        name: "Recruting-Office",
        component: RecrutingView
    },**/
/** v {
        path: "/recruting/:id",
        name: "Recruting-Office - Detail",
        component: DetailRecrutingView
    }, **/
   /** {
        path: "/mail/:mail",
        name: "Email Bestätigung",
        component: EmailSave
    }, **/
    {path: "/:catchAll(.*)", redirect: '/404'},


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
