
import {defineComponent} from 'vue';

import store from "@/store";
import rest from "@/rest";
import router from "@/router";

export default defineComponent({
  name: 'ContactDetailView',
  components: {},
  data(): { data: any, prvvisible: any, prvimgs: any[], prvindex: number } {
    return {
      data: {},
      prvvisible: false,
      prvimgs: [],
      prvindex: 0,
    };
  },
  async created() {
    if (!await store.getters.isLoggedIn) {
      this.$router.push("/login");
    }
    if (!this.$route.params.id || Number(this.$route.params.id) == 0) {
      this.$router.push("/login");
      return;
    }

    this.data = (await rest.POST("contact/detailed", {id: Number(this.$route.params.id)})).data["contact"];
    this.data.activeImage = 0;

  },
  methods: {
    handleHide() {
      this.prvvisible = false;
    },
    ShowImageDetails(wanted: any) {
      this.prvvisible = true;
      this.prvindex = 0;
      let list = [];
      for (const img of wanted.images) {
        list.push(this.RenderImageLink(img));
      }
      this.prvimgs = list;
    },
    SelectImage(contact: any, index: number) {
      contact.activeImage = index;
    },
    RenderImageLink(image: string) {

      // console.log(image);
      return rest.url + "file?file=" + image;
    },
    timeSince(date: DateConstructor) {

      let seconds = Math.floor(((new Date() as any) - (date as any)) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " Jahren";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " Monaten";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " Tagen";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " Stunden";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " Minuten";
      }
      return Math.floor(seconds) + " Sekunden";
    },
  }

});
