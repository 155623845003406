
import {defineComponent} from 'vue';
import rest from "@/rest";

export default defineComponent({
  name: "TrainingsControlComponent",
  data() {
    return {
      trainings: []
    };
  },
  async created() {
    if (!await rest.IsLoggedIn()) {
      this.$router.push("/login");
    }
    this.trainings = (await rest.GET("trainings/gettrainings")).data["list"];
  },
  methods: {
    showDetailed(id: any) {

      this.$router.push("/training/"+id);
    },
    async revokeToken(training: any) {
      this.$confirm.require({
        message: `Willst du die Ausbildung ${training.training} wirklich löschen?`,
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: async () => {


          training.beingDeleted = true;
          if ((await rest.POST("training/deletetraining", {id: training.training})).data) {
            alert("training erfolgreich gelöscht!");
          } else {
            alert("Tokenlöschung fehlgeschlagen");
          }
          training.beingDeleted = false;
          this.trainings = (await rest.GET("training/gettrainings")).data["list"];
        },
        reject: () => {
          //
        }
      });

    },
    async CreateToken() {
      this.$router.push("/registertraining");
    },
    timeSince(date: DateConstructor) {

      let seconds = Math.floor(((new Date() as any) - (date as any)) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " Jahren";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " Monaten";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " Tagen";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " Stunden";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " Minuten";
      }
      return Math.floor(seconds) + " Sekunden";
    },
  }
})
