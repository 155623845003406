
import {defineComponent} from 'vue';
import store from "@/store";
import rest from "@/rest";

export default defineComponent({
  name: "ContactsControlComponent",
  data() {
    return {
      contacts: []
    };
  },
  async created() {
    if (!await store.getters.isLoggedIn) {
      this.$router.push("/login");
    }
    // console.log(await store.getters.isLoggedIn + "logged");
    this.contacts = (await rest.GET("contact/list")).data["list"];
  },
  methods: {
    timeSince(date: DateConstructor) {

      let seconds = Math.floor(((new Date() as any) - (date as any)) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " Jahren";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " Monaten";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " Tagen";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " Stunden";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " Minuten";
      }
      return Math.floor(seconds) + " Sekunden";
    },
    async deleteDetailed(contact: any) {
      this.$confirm.require({
        message: `Willst du das Kontaktformular ${contact.id} zu ${contact.fugitive} wirklich löschen?`,
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: async () => {

          contact.beingDeleted = true;
          await rest.POST("contact/delete", {id: contact.id});
          this.contacts = (await rest.GET("contact/list")).data["list"];
          contact.beingDeleted = false;
        },
        reject: () => {
          //
        }
      });

    },
    showDetailed(id: number) {

      this.$router.push("/contactdetail/"+id);
    }
  }
})
