
import {defineComponent} from 'vue';

import store from "@/store";
import rest from "@/rest";

export default defineComponent({
  name: 'AccountDetailView',
  components: {},
  data() {
    return {
      data: {}
    };
  },
  async created() {
    if (!await store.getters.isLoggedIn) {
      this.$router.push("/login");
    }
    if (!store.getters.detailedAccount || store.getters.detailedAccount == 0 || !await store.getters.isManagerAccount) {
      this.$router.push("/login");
      return;
    }

    this.data = (await rest.POST("account/detailed", {id: store.getters.detailedAccount})).data["account"];


  },
  methods: {
    timeSince(date: DateConstructor) {

      let seconds = Math.floor(((new Date() as any) - (date as any)) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " Jahren";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " Monaten";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " Tagen";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " Stunden";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " Minuten";
      }
      return Math.floor(seconds) + " Sekunden";
    },
  }

});
