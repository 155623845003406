

import {defineComponent, PropType} from 'vue';
import rest from '../rest';
import store from "@/store";
import AuthService from "@/rest";

declare interface BaseComponentData {
  wantedList: { id: number, label: string, text: string, price: number, creator: number, images: string[], activeImage: number, beingDeleted: boolean }[] | undefined,

  isSessionLoggedIn: boolean,
  prvvisible: boolean,
  prvimgs: any[],
  prvindex: number
}

export default defineComponent({
  name: 'WantedView',
  components: {},
  data(): BaseComponentData {
    return {
      wantedList: undefined,
      isSessionLoggedIn: false,
      prvvisible: false,
      prvimgs: [],
      prvindex: 0,
    }
  },
  computed: {},


  created: async function () {
    this.LoadWanteds();

    this.isSessionLoggedIn = await store.getters.isLoggedIn;
  },
  methods: {
    ShowImageDetails(wanted: any) {
      this.prvvisible = true;
      this.prvindex = 0;
      let list = [];
      for (const img of wanted.images) {
        list.push(this.RenderImageLink(img));
      }
      this.prvimgs = list;
    },
    handleHide() {
      this.prvvisible = false;
    },

    MeasureAge(wanted: any): number {

      var ageDifMs = Date.now() - Date.parse(wanted.dob);
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);

    },
    RenderImageLink(image: string) {

      // console.log(image);
      return rest.url + "file?file=" + image;
    },
    async LoadWanteds() {
      const json: any = (await rest.GET("wanteds/wantedlist")).data;
      this.wantedList = json;
      if (this.wantedList) {
        for (const wanted of this.wantedList) {

          wanted.activeImage = 0;


          wanted.beingDeleted = false;
        }
      }

    },

    async DeleteWanted(wanted: any) {

      this.$confirm.require({
        message: `Willst du den Wanted ${wanted.label} wirklich löschen?`,
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: async () => {

          wanted.beingDeleted = true;
          const response = (await rest.POST("wanteds/delete", {wantedid: wanted.id})).data;
          if (response.msg == "deleted") {
            wanted.beingDeleted = false;
            this.LoadWanteds();
          }
        },
        reject: () => {
          //
        }
      });

    },
    SelectImage(wanted: any, index: number) {
      wanted.activeImage = index;
    },
    formatPrice(price: number): string {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter.format(price);
    }
  }

});
