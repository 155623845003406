<template>
  <div class="">
    <ModalPopup v-if="AccessGetting" title="Zugang anfordern" @closeEvent="closeAccessField" :fields="AccessFields"
                @submitEvent="SubmitAccess"/>

    <ConfirmDialog></ConfirmDialog>


    <Toast></Toast>
    <CookieConsent v-if="!this.IsCookieGranted()"/>

    <link rel="shortcut icon" type="image/png" href="../public/favicon.ico"/>
    <Disclosure as="nav" class="bg-gray-800 dcc" v-slot="{ open }">
      <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div class="relative flex items-center justify-between h-16">
          <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">

            <DisclosureButton
                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true"/>
              <XIcon v-else class="block h-6 w-6" aria-hidden="true"/>
            </DisclosureButton>
          </div>
          <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div class="flex-shrink-0 flex items-center">

              <img class="hidden lg:block h-10 w-auto" src="./assets/logo.png" alt="F.I.B. Logo"/>
            </div>
            <div class="hidden sm:block sm:ml-6">
              <div class="flex space-x-4">
                <template v-for="item in navigation" :key="item.name">
                  <router-link :to="item.path" custom v-slot="{ href, isActive }" v-if="!IsProhibited(item.path)">
                    <a :href="href"
                       :class="[isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']">{{
                        item.name
                      }}</a>

                  </router-link>
                </template>


              </div>
            </div>
          </div>
          <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

            <button id="theme-toggle" type="button" v-on:click="ToggleDarkMode()"
                    class="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5">
              <svg id="theme-toggle-dark-icon" class="w-5 h-5" v-if="darkModeState == false" fill="currentColor"
                   viewBox="0 0 20 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
              </svg>
              <svg id="theme-toggle-light-icon" v-if="darkModeState == true" class="w-5 h-5" fill="currentColor"
                   viewBox="0 0 20 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                    fill-rule="evenodd" clip-rule="evenodd"></path>
              </svg>
            </button>

            <!-- Profile dropdown -->
            <!-- <button v-on:click="OpenDialogField"
               :class="['bg-gray-900 text-white animate-pulse', 'ml-2.5 px-3 py-2 rounded-md text-sm font-medium']">Auktions-Zugriff</button>
  !-->
            <Menu as="div" class="ml-3 relative" v-if="isSessionLoggedIn">
              <div>
                <MenuButton
                    class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span class="sr-only">Open user menu</span>
                  <img class="h-8 w-8 rounded-full" src="./assets/user.png" alt=""/>
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100"
                          enter-from-class="transform opacity-0 scale-95"
                          enter-to-class="transform opacity-100 scale-100"
                          leave-active-class="transition ease-in duration-75"
                          leave-from-class="transform opacity-100 scale-100"
                          leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                    class="origin-top-right absolute left-7 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem>
                    <a class="block px-4 py-2 text-sm text-gray-700">Hallo,
                      {{ username }}!</a>

                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <router-link to="/cp">
                      <a :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Verwaltung</a>
                    </router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <router-link to="/createwanted">
                      <a :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Wanted
                        erstellen</a>
                    </router-link>
                  </MenuItem>

                  <MenuItem v-slot="{ active }">
                    <a v-on:click="logout"
                       :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Abmelden</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <DisclosurePanel class="sm:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1">
          <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href"
                            :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']"
                            :aria-current="item.current ? 'page' : undefined">{{ item.name }}
          </DisclosureButton>
        </div>
      </DisclosurePanel>
    </Disclosure>
    <div class="min-h-[calc(100vh-17.8rem)] dark:bg-gray-900">
      <div class="max-w-7xl mx-auto overflow-hidden">

        <div id="alert-additional-content-1"
             class="relative animate-in slide-in-from-top mb-4 mt-5 rounded-lg max-w-full mx-auto" role="alert"
             :style="{ backgroundColor: this.hexToRgba(alert.color, 0.8) }" v-for="alert in alerts" :key="alert.id">
          <div class="p-4">
            <div class="flex items-center">
              <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                   :style="{ textColor: this.hexToRgba(alert.color, 0.70) }" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clip-rule="evenodd"></path>
              </svg>
              <h3 class="text-lg font-medium" :style="{ textColor: this.hexToRgba(alert.color, 0.70) }">{{
                  alert.title
                }}
              </h3>
            </div>
            <div class="mt-2 mb-4 text-sm" :style="{ textColor: this.hexToRgba(alert.color, 0.70) }">
              {{ alert.text }}
            </div>
            <div class="flex" v-if="alert.link != ''">
              <button type="button" v-on:click="OpenAlertTab(alert)"
                      :style="{ backgroundColor: this.hexToRgba(alert.color, 0.70) }"
                      class="text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-blue-800 dark:hover:bg-blue-900">
                <svg class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                  <path fill-rule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clip-rule="evenodd"></path>
                </svg>
                Mehr anzeigen
              </button>
            </div>
          </div>

        </div>
        <template v-if="connectionResolved == undefined">
          <div class="text-center mx-auto mt-5">
            <h1 class="font-mono">Stelle Verbidung zum Server her</h1>
            <br>
            <svg role="status" class="inline w-64 h-64 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"/>
              <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"/>
            </svg>
          </div>

        </template>
        <template v-else-if="connectionResolved == false">
          <div class="flex items-center justify-center mt-20">
            <div class="px-40 py-20 rounded-md shadow-xl  bg-gradient-to-r
    from-blue-900
    to-yellow-700 animate-in zoom-in">
              <div class="flex flex-col items-center">
                <h1 class="font-bold text-white text-9xl">Fehler</h1>

                <h6 class="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
                  <span class="text-red-500 animate-pulse duration-1000">Oops!</span>
                </h6>

                <p class="mb-8 text-center text-white md:text-lg">
                  Es konnte keine Verbindung zu unseren Systemen hergestellt werden. <br> Bitte überprüfe deine
                  Internetverbindung oder versuche es später erneut.
                </p>


              </div>
            </div>
          </div>

        </template>
        <template v-else>
          <router-view class="animate-in zoom-in"/>
        </template>

      </div>

    </div>

    <footer class="dark bg-gray-800 p-4 ftc sm:p-6 dark:bg-gray-800">
      <div class="max-w-7xl mx-auto">
        <div class="md:flex md:justify-between">
          <div class="mb-6 md:mb-0">
            <a class="flex items-center">
              <img src="./assets/logo.png" class="mr-3 h-8" alt="">
              <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Federal Investigation
                Bureau</span>
            </a>
          </div>

          <div class="grid grid-cols-3 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 class="mb-1 text-sm font-semibold text-gray-900 uppercase dark:text-white">Account</h2>
              <ul class="text-gray-600 dark:text-gray-400">

                <router-link to="/login">
                  <li class="">
                    <a class="hover:underline">Login</a>
                  </li>

                </router-link>
              </ul>
              <ul class="text-gray-600 dark:text-gray-400">

                <router-link to="/register">
                  <li class="mb-4">
                    <a class="hover:underline">Registrierung</a>
                  </li>

                </router-link>
              </ul>
            </div>
            <div>
              <h2 class="mb-1 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
              <ul class="text-gray-600 dark:text-gray-400">

                <router-link to="/imprint">
                  <li class="">
                    <a class="hover:underline">Impressum</a>
                  </li>

                </router-link>
              </ul>
              <ul class="text-gray-600 dark:text-gray-400">

                <a href="https://legal.fib.san-andreas.services/datenschutz.pdf">
                  <li class="mb-4">
                    <a class="hover:underline">Datenschutzerklärung</a>
                  </li>

                </a>
              </ul>
            </div>

            <div>
              <h2 class="mb-1 text-sm font-semibold text-gray-900 uppercase dark:text-white">Sonstiges</h2>
              <ul class="text-gray-600 dark:text-gray-400">
                <a href="https://legal.fib.san-andreas.services/1_informationslohn.pdf">Informationslohn¹</a>

              </ul>

            </div>

          </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8">


        <div class="sm:flex sm:items-center sm:justify-between">

          <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a
              class="hover:underline">Federal Investigation Bureau (Fiktives Unternehmen)</a>. All Rights Reserved.

          </span>
          <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            Besuche Heute: {{ visitsToday }}
          </span>
          <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            Besuche Insgesamt: {{ visitsAll }}
          </span>


        </div>
      </div>

    </footer>
  </div>
</template>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
<script>
import {Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import {MenuIcon, XIcon} from '@heroicons/vue/outline'
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import router from "./router";
import store from "@/store";
import rest from "./rest";
import axios from "axios";
import CookieConsent from "@/components/CookieConsent";
import ModalPopup from "@/components/ModalPopup";
import {ToastSeverity} from "primevue/api";


export default {
  components: {
    CookieConsent,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    XIcon,
    ConfirmDialog,
    ModalPopup,
    Toast
  },
  computed: {},
  data() {
    return {
      username: store.getters.getUser.username,
      isSessionLoggedIn: false,
      notifications: [],
      visitsToday: 0,
      darkModeState: false,
      visitsAll: 0,
      alerts: [],
      connectionResolved: undefined,
      AccessGetting: false,
      AccessFields: [{
        name: "name",
        type: "text",
        label: "Vor-/ Nachname",
        placeholder: "Andrew Tate",
        needed: true
      },
        {
          name: "tel",
          label: "Telefonnummer",
          type: "text",
          placeholder: "012-3456",
          needed: false,
        }],
    }
  },

  setup() {

    return {
      navigation: router.getRoutes()
    }
  },
  async mounted() {

    //   this.$toast.add({severity:'success', summary: 'Success Message', detail:'Order submitted', life: 3000});

    this.RefreshDarkMode();

    await rest.POST("stats/pageopened");
    this.alerts = (await rest.GET("stats/alerts/get/active")).data;


    axios.get(rest.url + "isOnline").then(response => {
      this.connectionResolved = true;
    }).catch(error => {
      this.connectionResolved = true;
      return;
    });

    this.isSessionLoggedIn = await store.getters.isLoggedIn;
    this.visitsToday = (await rest.GET("stats/getvisits")).data["today"];
    this.visitsAll = (await rest.GET("stats/getvisits")).data["all"];
  },
  async created() {
    // this.connectionResolved = (await rest.GET(""));


  },
  methods: {
    IsDarkModeEnabled() {
      if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        return true;
      } else {
        return false;
      }
    },
    ToggleDarkMode() {
      if (this.IsDarkModeEnabled()) {
        localStorage.theme = 'light';
      } else {
        localStorage.theme = 'dark';
      }
      this.RefreshDarkMode();

    },
    RefreshDarkMode() {
      if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.documentElement.classList.add('dark')
        this.darkModeState = true;
      } else {
        document.documentElement.classList.remove('dark')
        this.darkModeState = false;
      }
    },
    closeAccessField() {
      this.AccessGetting = false;
    },
    async SubmitAccess(data) {


      this.closeAccessField();


      var textArea = document.createElement("textarea");
      const token = (await rest.POST("auction/genaccess", {
        name: data.name,
        tel: data.tel
      })).data;
      //
      // *** This styling is an extra step which is likely not required. ***
      //
      // Why is it here? To ensure:
      // 1. the element is able to have focus and selection.
      // 2. if the element was to flash render it has minimal visual impact.
      // 3. less flakyness with selection and copying which **might** occur if
      //    the textarea element is not visible.
      //
      // The likelihood is the element won't even render, not even a
      // flash, so some of these are just precautions. However in
      // Internet Explorer the element is visible whilst the popup
      // box asking the user for permission for the web page to
      // copy to the clipboard.
      //

      // Place in the top-left corner of screen regardless of scroll position.
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;

      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em';
      textArea.style.height = '2em';

      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = 0;

      // Clean up any borders.
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';

      // Avoid flash of the white box if rendered for any reason.
      textArea.style.background = 'transparent';


      textArea.value = token;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
      } catch (err) {
        console.log('Oops, unable to copy');
      }

      document.body.removeChild(textArea);


      this.$toast.add({
        severity: ToastSeverity.INFO,
        summary: 'Zugangsanforderung',
        detail: "Bitte bewahre diesen Schlüssel sicher auf und melde dich damit zur Verifizierung am F.I.B.-Tower! " + token
      });


    },
    OpenDialogField(e) {
      e.preventDefault();
      this.AccessGetting = true;
    },
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    IsCookieGranted() {
      return this.getCookie("siD-cAS") && (this.getCookie("siD-cAS")) == "true";
    },
    OpenAlertTab(alert) {
      window.open(alert.link, '_blank');
    },
    /*(origg) {

      var a, isPercent,
          rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
          alpha = (rgb && rgb[4] || "").trim(),
          hex = rgb ?
              (rgb[1] | 1 << 8).toString(16).slice(1) +
              (rgb[2] | 1 << 8).toString(16).slice(1) +
              (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

      if (alpha !== "") {
        a = alpha;
      } else {
        a = 0o1;
      }
      // multiply before convert to HEX
      a = ((a * 255) | 1 << 8).toString(16).slice(1)
      hex = hex + a;
      console.log(hex);
      return hex;
    },*/
    hexToRgba(hex, opactiy) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opactiy})`
          : null;
    },
    IsProhibited(path) {
      let prohibited = ["*", "/mail/:mail", "/404", "/recruting/:id", "/:catchAll(.*)", "/registertraining", "/training/:id", "/createwanted", "/login", "/auction/:id", "/register", "/accountdetail", "/cp", "/privacy", "/thanksforproviding", "/contactdetail/:id", "/imprint"];
      // console.log(path);
      if (prohibited.includes(path)) {
        return true;
      }
      return false;
    }
    ,
    logout() {
      store.dispatch('logout');
      this.$router.push('/login');
    }
  }
}
</script>

<style>
.dbs {
}


body {
  height: 100%;
  width: 100%;
  position: relative;

}

* {
  @apply transition-colors duration-700;
}

</style>
