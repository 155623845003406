import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center mb-6" }
const _hoisted_2 = { class: "inline-block mt-5" }
const _hoisted_3 = { class: "text-center max-w-sm rounded overflow-hidden shadow-lg" }
const _hoisted_4 = { class: "px-6 py-4" }
const _hoisted_5 = { class: "font-bold text-xl mb-2" }
const _hoisted_6 = { class: "font-medium mb-2" }
const _hoisted_7 = { class: "text-gray-700 text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, "Account " + _toDisplayString(_ctx.data.username) + "(" + _toDisplayString(_ctx.data.id) + ")", 1),
          _createElementVNode("div", _hoisted_6, "Letzter Login: vor " + _toDisplayString(_ctx.timeSince(Date.parse(_ctx.data.last_login))) + " (" + _toDisplayString(_ctx.data.last_login) + ") ", 1),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.data.text), 1)
        ])
      ])
    ])
  ]))
}