
import {defineComponent} from 'vue';
import store from "@/store";
import rest from "@/rest";
import ModalPopup from "@/components/ModalPopup.vue";

export default defineComponent({
  name: "AuctionControlComponent",
  components: {
    ModalPopup,
  },
  data() {
    return {
      auctions: [],
      AlertBeingCreated: false,
      AlertCreationModelFields: [{
        name: "title",
        type: "text",
        label: "Titel",
        placeholder: "Pistole",
        needed: true
      },
        {
          name: "text",
          label: "Text",
          type: "text",
          placeholder: "",
          needed: true,
        },
        {
          name: "date",
          label: "Datum",
          type: "date",
          placeholder: "",
          needed: true,
        },
        {
          name: "start",
          label: "Startbetrag",
          type: "text",
          placeholder: "",
          needed: true,
        },
        {
          name: "image",
          label: "Bild",
          type: "file",
          placeholder: "",
          needed: true,
        },
        {
          name: "type",
          label: "Gruppe",
          type: "select",
          placeholder: "",
          options: [{key: "handweapons", value: "Handwaffen"}, {key: "propertys", value: "Immobilien"}, {key: "cars", value: "Kraftfahrzeuge"}, {key: "nor", value: "Sonstiges"}],
          needed: true,
        }],
    };
  },
  async created() {
    if (!await store.getters.isLoggedIn || !await store.getters.isManagerAccount) {
      this.$router.push("/login");
    }
    this.auctions = (await rest.GET("auction/clearauctions")).data;
  },
  methods: {
    timeTo(date: DateConstructor) {
      console.log(date);
      let seconds = Math.floor(((date as any) - (new Date() as any)) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " Jahren";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " Monaten";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " Tagen";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " Stunden";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " Minuten";
      }
      return Math.floor(seconds) + " Sekunden";
    },
    getRandomInt(min: number, max: number): number {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    },
    async SubmitAlertModel(data: any) {
      let id = this.getRandomInt(1111, 999999);


      const formData = new FormData();

      for (const i in Object.keys(data.image)) {
        formData.append('imagesArray', data.image[i]);

      }

      // sending file to the backend
      await rest.POST("auction/uploadimages?id=" + id, formData);


      const images = [];
      for (const i in Object.keys(data.image)) {
        const file: any = data.image[i];

        images.push(file.name);

      }

      await rest.POST("auction/create", {
        id: id,
        name: data.title,
        text: data.text.replace(/'/g, "\\'").replace(/"/g, '\\"'),
        color: data.color,
        link: data.link,
        endtime: data.date.toString(),
        imagenames: images,
        start: data.start,
        type: data.type
      })

      this.closeModalAlert();

      this.auctions = (await rest.GET("auction/clearauctions")).data;
    },
    async closeModalAlert() {
      this.AlertBeingCreated = false;
    },
    async activateAlert(alert: any) {
      this.$confirm.require({
        message: `Willst du den Alert ${alert.title} wirklich aktivieren?`,
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {


          alert.beingActivated = true;
          if ((await rest.POST("stats/alerts/activate", {id: alert.id}))) {
            window.alert("Alert erfolgreich aktiviert!");
          } else {
            window.alert("Alert aktivierung fehlgeschlagen");
          }
          alert.beingActivated = false;
          this.auctions = (await rest.GET("stats/alerts/get/all")).data;
        },
        reject: () => {
          //
        }
      });


    },
    async deactivateAlert(alert: any) {
      this.$confirm.require({
        message: `Willst du den Alert ${alert.title} wirklich deaktivieren?`,
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: async () => {


          alert.beingDeleted = true;
          if ((await rest.POST("stats/alerts/deactivate", {id: alert.id}))) {
            window.alert("Alert erfolgreich deaktiviert!");
          } else {
            window.alert("Alert fehlgeschlagen");
          }
          alert.beingDeleted = false;
          this.auctions = (await rest.GET("stats/alerts/get/all")).data;
        },
        reject: () => {
          //
        }
      });


    },
    async CreateAuction() {
      this.AlertBeingCreated = true;
    },
    timeSince(date: DateConstructor) {

      let seconds = Math.floor(((new Date() as any) - (date as any)) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " Jahren";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " Monaten";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " Tagen";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " Stunden";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " Minuten";
      }
      return Math.floor(seconds) + " Sekunden";
    },
  }
})
