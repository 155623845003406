
import {defineComponent} from 'vue';

import store from "@/store";

export default defineComponent({
  name: 'ThanksForProviding',
  components: {},
  data() {
    return {

    };
  },

  methods: {

  }

});
