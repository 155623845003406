<template>
  <div class="text-center mb-6">

    <div class="inline-block mt-5">
      <div
          class="p-4 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form class="space-y-6" action="#">
          <h5 class="text-xl font-medium text-gray-900 dark:text-white">Anmelden</h5>
          <div>

            <input type="text" name="username" v-model="username"
                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                   required>
          </div>
          <div>

            <input type="password" name="password" v-model="password" placeholder="••••••••"
                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                   required>
          </div>
          <label v-if="msg != ''"
                 class="block uppercase align-middle tracking-wide text-gray-700 text-xs font-bold mb-2"
                 for="grid-state">
            {{ msg }}
          </label>

          <div type="submit"
               v-on:click="signUp"
               class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Login
          </div>

        </form>
      </div>
    </div>
  </div>

</template>

<script>
import AuthService from '@/rest';
import store from "@/store";

export default {
  name: "LoginView",
  data() {
    return {
      username: "",
      password: "",
      msg: ""
    }
  },
  async created() {
    if (await store.getters.isLoggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    async signUp() {
      try {
        const credentials = {
          username: this.username,
          password: this.password,

        };
        // console.log(credentials);
        const response = (await AuthService.POST("account/login", credentials)).data;

        this.msg = response.msg;
        const token = response.token;
        const user = response.user;
        this.$store.dispatch('login', {token, user});
        this.$router.push('/');

        this.msg = response.msg;
      } catch (error) {
        this.msg = error.response.data.msg;
      }
    }
  }
}
</script>
