
import {defineComponent} from 'vue';
import store from "@/store";
import rest from "@/rest";

export default defineComponent({
  name: "TokenControlComponent",
  data() {
    return {
      tokens: []
    };
  },
  async created() {
    if (!await store.getters.isLoggedIn || !await store.getters.isManagerAccount) {
      this.$router.push("/login");
    }
    this.tokens = (await rest.GET("account/gettokens")).data["list"];
  },
  methods: {
    async revokeToken(token: any) {
      this.$confirm.require({
        message: `Willst du den Token ${token.token} wirklich löschen?`,
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: async () => {


          token.beingDeleted = true;
          if ((await rest.POST("account/deletetoken", {id: token.token})).data.state) {
            alert("Token erfolgreich gelöscht!");
          } else {
            alert("Tokenlöschung fehlgeschlagen");
          }
          token.beingDeleted = false;
          this.tokens = (await rest.GET("account/gettokens")).data["list"];
        },
        reject: () => {
          //
        }
      });

    },
    async CreateToken() {
      alert(`Token erstellt: ${(await rest.POST("account/generatetoken")).data.token}`);

      this.tokens = (await rest.GET("account/gettokens")).data["list"];
    },
    timeSince(date: DateConstructor) {

      let seconds = Math.floor(((new Date() as any) - (date as any)) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " Jahren";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " Monaten";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " Tagen";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " Stunden";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " Minuten";
      }
      return Math.floor(seconds) + " Sekunden";
    },
  }
})
