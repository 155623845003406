
import {defineComponent} from 'vue';
import store from "@/store";
import rest from "@/rest";

export default defineComponent({
  name: "AccountsControlComponent",
  data() {
    return {
      accounts: []
    };
  },
  async created() {
    if (!await store.getters.isLoggedIn) {
      this.$router.push("/login");
    }


    this.accounts = (await rest.GET("account/accountlist")).data["list"];

  },
  methods: {
    timeSince(date: DateConstructor) {

      let seconds = Math.floor(((new Date() as any) - (date as any)) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " Jahren";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " Monaten";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " Tagen";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " Stunden";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " Minuten";
      }
      return Math.floor(seconds) + " Sekunden";
    },
    async deleteAccount(account: any) {
      this.$confirm.require({
        message: `Willst du den Account ${account.username} wirklich löschen?`,
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: async () => {

          account.beingDeleted = true;
          if ((await rest.POST("account/delete", {id: account.id})).data.state) {
            alert("Account erfolgreich gelöscht!");
          } else {
            alert("Accountlöschung fehlgeschlagen");
          }
          account.beingDeleted = false;
          this.accounts = (await rest.GET("account/accountlist")).data["list"];
        },
        reject: () => {
          //
        }
      });


    },
    showDetailed(id: number) {
      store.dispatch('setDetailedAccount', {id});

      this.$router.push("/accountdetail");
    }
  }
})
