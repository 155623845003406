import axios, {AxiosError, AxiosResponse} from 'axios';

//
//const url = 'https://rest.fib.san-andreas.services:443/api/';

export default {
    url: 'https://rest.preview.san-andreas.services:443/api/',
    // url: 'http://localhost:3000/api/',
    //// url: 'https://rest.fib.san-andreas.services:443/api/',
    POST(hurl: string, ...credentials: any): Promise<AxiosResponse<any, any>> {
        return axios
            .post(this.url + hurl + '/', ...credentials)
            .catch((reason: AxiosError) => {

                return reason.response || reason.request.response;
            })
            .then(response => response);

    },
    DELETE(hurl: string, ...credentials: any): Promise<AxiosResponse<any, any>> {
        return axios
            .delete(this.url + hurl + '/', ...credentials)
            .catch((reason: AxiosError) => {

                return reason.response || reason.request.response;
            })
            .then(response => response);

    },
    PATCH(hurl: string, ...credentials: any): Promise<AxiosResponse<any, any>> {
        return axios
            .patch(this.url + hurl + '/', ...credentials)
            .catch((reason: AxiosError) => {

                return reason.response || reason.request.response;
            })
            .then(response => response);

    },
    GET(hurl: string): Promise<AxiosResponse> {
        return axios.get(this.url + hurl).then(response => response);
    },
    async IsLoggedIn() {
        return (await this.GET("account/tokencheck")).data["state"];
    },
    async IsManagerAccount() {
        return (await this.GET("account/ismanageraccount")).data["state"];
    }
};
