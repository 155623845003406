
import {defineComponent} from "vue";
import rest from "@/rest";

export default defineComponent({
  name: "RecrutingApplysComponent",
  data() {
    return {
      applys: [],
    };
  },
  async created() {
    if (!(await rest.IsLoggedIn())) {
      this.$router.push("/login");
    }
    const response = await rest.GET("recruting/list");

    this.applys = this.filteredApplys(response.data);
  },
  computed: {
    //
  },
  methods: {
    filteredApplys(applys: any) {
      const currentDate = new Date();
      const firstList = applys.sort((b: any, a: any) => (currentDate as any - Date.parse(b.date)) - (currentDate as any - Date.parse(a.date)));
      const secondList = firstList.sort((b: any, a: any) => (Number(Number(a.state.state) == 1 || Number(a.state.state) == 2)) - (Number(Number(b.state.state) == 1 || Number(b.state.state) == 2)))
      return secondList;
    },
    GetTag(data: any) {
      switch (data) {
        case 0:
          return [
            "bg-orange-100 dark:bg-orange-700 dark:text-orange-100 text-orange-800",
            "Ausstehend",
          ];

        case 1:
          return [
            "bg-yellow-100 dark:bg-yellow-700 dark:text-yellow-100 text-yellow-800",
            "In Bearbeitung",
          ];

        case 2:
          return [
            "bg-yellow-100 dark:bg-yellow-700 dark:text-yellow-100 text-yellow-800",
            "In Bearbeitung (Eingeladen)",
          ];
        case 3:
          return [
            "bg-red-100 dark:bg-red-700 dark:text-red-100 text-red-800",
            "Abgelehnt",
          ];
        case 4:
          return [
            "bg-green-100 dark:bg-green-700 dark:text-green-100 text-green-800",
            "Angenommen",
          ];
      }
    },

    timeSince(date: any) {

      let seconds = Math.floor(((new Date() as any) - (date as any)) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " Jahren";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " Monaten";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " Tagen";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " Stunden";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " Minuten";
      }
      return Math.floor(seconds) + " Sekunden";
    },
  },
});
