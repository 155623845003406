
import {defineComponent} from 'vue';
import rest from "@/rest";

export default defineComponent({
  name: 'ContactView',
  components: {},
  data() {
    return {
      wantedList: [],
      name: "",
      phonenumber: "",
      fugitive: "",
      information: "",
      selectedFiles: [],
      IsCFXBrowser: (document.location.href.endsWith('?fivem') || navigator.userAgent.includes('CitizenFX'))
    }
  },
  created: async function () {
    const json: any = (await rest.GET("wanteds/wantedlist")).data;
    this.wantedList = json;
  },

  methods: {
    fileChange(e: any) {
      const selectedFile = e.target.files; // accessing file
      // console.log(selectedFile.length + " FILES");
      this.selectedFiles = selectedFile;
    },
    getRandomInt(min: number, max: number): number {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    },
    async onUploadFile(contactId: string) {
      if (this.selectedFiles.length == 0) return;
      const formData = new FormData();

      for (const i in Object.keys(this.selectedFiles)) {
        formData.append('imagesArray', this.selectedFiles[i]);

      }

      // sending file to the backend
      await rest.POST("contact/uploadimages?id=" + contactId, formData);

    },
    async submitInfo() {
      const contactId = this.getRandomInt(1, 6000) + "" + this.getRandomInt(5000, 100000);
      const images = [];
      if (!this.IsCFXBrowser) {
        await this.onUploadFile(contactId);

        for (const i in Object.keys(this.selectedFiles)) {
          const file: any = this.selectedFiles[i];
          images.push(file.name);

        }
      }
      rest.POST("contact/submit", {
        sender: this.name,
        sendernumber: this.phonenumber,
        fugitive: this.fugitive,
        text: this.information,
        contactId,
        imagenames: images,
        type: "fugitive"

      });
      this.$router.push("/thanksforproviding");
    }
  }

});
