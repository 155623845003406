<template>
  <div tabindex="-1" aria-hidden="true"
       class="backdrop-blur overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full mx-auto md:inset-0 h-modal md:h-full">
    <div class="relative p-4 w-full max-w-md h-full md:h-auto mx-auto top-5">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div class="flex justify-end p-2">
          <button v-on:click="Close" type="button"
                  class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  data-modal-toggle="authentication-modal">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"></path>
            </svg>
          </button>
        </div>
        <div class="px-6 pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8" action="#">
          <h3 class="text-xl font-medium text-gray-900 dark:text-white">{{ title }}</h3>

          <template v-for="field in fields" :key="field.label">

            <template v-if="field.type == 'text'">
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ field.label }}</label>
              <input type="text" v-model="field.value"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                     :placeholder="field.placeholder">
            </template>
            <template v-if="field.type == 'textarea'">
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ field.label }}</label>
              <textarea v-model="field.value"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        :placeholder="field.placeholder"/>
            </template>

            <template v-if="field.type == 'select'">
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ field.label }}</label>
              <select v-model="field.value"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      :placeholder="field.placeholder">


                <option v-for="option in field.options" :value="option.key" :key="option.key">{{ option.value }}
                </option>
              </select>
            </template>

            <template v-else-if="field.type == 'color'">
              <div>
                <label class="block uppercase align-middle tracking-wide text-gray-700 text-xs font-bold mb-2"
                       for="grid-state">
                  {{ field.label }}
                </label>
                <div
                    class="bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                  <input type="color"
                         v-model="field.value"

                         class="rounded-lg w-full">

                </div>

              </div>
            </template>

            <template v-else-if="field.type == 'date'">
              <div>
                <label class="block uppercase align-middle tracking-wide text-gray-700 text-xs font-bold mb-2"
                       for="grid-state">
                  {{ field.label }}
                </label>
                <div
                    class="bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                  <input type="date"
                         v-model="field.value"

                         class="rounded-lg w-full">

                </div>

              </div>
            </template>


            <template v-else-if="field.type == 'file'">
              <div class="flex justify-center">
                <div class="mb-3 w-96">
                  <label class="block uppercase align-middle tracking-wide text-gray-700 text-xs font-bold mb-2"
                         for="grid-state">
                    {{ field.label }}
                  </label>
                  <input @change="fileChange(field, $event)" class="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-gray-50 bg-clip-padding
    border border-solid border-gray-300
    rounded-lg
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" type="file"
                         accept="image/*" multiple>
                </div>
              </div>
            </template>
          </template>


          <button v-on:click="Submit"
                  class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Absenden
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import rest from "@/rest";

export default defineComponent({
  name: "ModalPopup",
  props: {
    title: {type: String},
    fields: {type: Array}
  },
  methods: {
    Close() {
      this.$emit("closeEvent");
      for (const field of this.fields) {
        if (field.type == "color") return;
        field.value = "";
      }
    },
    fileChange(field, e) {
      field.value = e.target.files;
    },
    Submit() {
      if (this.fields.filter(x => (!x.value || x.value == "") && x.needed).length > 0) return;
      let obj = new Object();

      for (const field of this.fields) {
        obj[field.name] = field.value;
      }

      this.$emit("submitEvent", obj);

      for (const field of this.fields) {
        if (field.type == "color") return;
        field.value = "";

      }
    }
  }
})
</script>
