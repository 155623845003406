
import {defineComponent} from 'vue';
import store from "@/store";
import rest from "@/rest";

export default defineComponent({
  name: "EmployeeControlComponent",
  data() {
    return {
      employees: []
    };
  },
  async created() {
    if (!await store.getters.isLoggedIn) {
      this.$router.push("/login");
    }


    this.employees = (await rest.GET("employees/list")).data;

  },
  methods: {

  }
})
