import {createStore} from 'vuex'

import createPersistedState from 'vuex-persistedstate';
import Axios from "axios";
import rest from "@/rest";

const getDefaultState = () => {
    return {
        token: '',
        user: {},
        detailedContact: 0,
        detailedAccount: 0
    };
};


export default createStore({
    strict: true,
    plugins: [createPersistedState()],
    state: getDefaultState(),
    getters: {
        async isLoggedIn() {
            return (await rest.GET("account/tokencheck")).data["state"];
        },
        async isManagerAccount() {
            return (await rest.GET("account/ismanageraccount")).data["state"];
        },
        isLoggedInOLD: state => {
            return state.token;
        },
        getUser: state => {
            return state.user;
        },
        detailedContact: state => {
            return state.detailedContact;
        },
        detailedAccount: state => {
            return state.detailedAccount;
        },
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_DETAILED_CONTACT: (state, id) => {
            state.detailedContact = id;
        },
        SET_DETAILED_ACCOUNT: (state, id) => {
            state.detailedAccount = id;
        },
        SET_USER: (state, user) => {
            state.user = user;
        },
        RESET: state => {
            Object.assign(state, getDefaultState());
        }
    },
    actions: {
        login: ({commit, dispatch}, {token, user}) => {
            commit('SET_TOKEN', token);
            commit('SET_USER', user);
            // set auth header
            Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        },
        setDetailedContact: ({commit, dispatch}, {id}) => {
            commit('SET_DETAILED_CONTACT', id);

            // set auth header

        },
        setDetailedAccount: ({commit, dispatch}, {id}) => {
            commit('SET_DETAILED_ACCOUNT', id);

            // set auth header

        },
        logout: ({commit}) => {
            commit('RESET', '');
        }
    }
})
