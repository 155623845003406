
import {defineComponent} from 'vue';

import store from "@/store";
import rest from "@/rest";
import {AxiosResponse} from "axios";

interface CreateView {
  selectedFiles: any[],
  label: any,
  text: any,
  price: any,
  gender: any,
  date: any,
  tags: string,
  faction: string,
  servertags: any[],
  priotiry: number,
  highestprior: number,
  profileImage: any,
  color: any
}

export default defineComponent({
  name: 'CreateWantedView',
  components: {},
  data(): CreateView {
    return {
      selectedFiles: [],
      label: "",
      text: "",
      price: "",
      gender: "",
      date: "",
      tags: "",
      faction: "Keine",
      servertags: [],
      priotiry: 0,
      highestprior: 0,
      profileImage: undefined,
      color: "#FF0000"
    };
  },
  async mounted() {
    this.servertags = (await rest.GET("wanteds/gettags")).data["tags"];
    // console.log(this.servertags);
    this.highestprior = (await rest.GET("wanteds/gethighestpriotiry")).data["priority"];
  },
  async created() {
    if (!await store.getters.isLoggedIn) {
      this.$router.push("/login");
    }
  },
  computed: {
    PriorityText(): string {
      return "Priorität (aktuell höchste: " + this.highestprior + ")";
    }
  },
  methods: {


    fileChange2(e: any) {
      const selectedFile = e.target.files[0]; // accessing file

      this.profileImage = selectedFile;
    },
    fileChange(e: any) {
      const selectedFile = e.target.files; // accessing file
      // console.log(selectedFile.length + " FILES");
      this.selectedFiles = selectedFile;
    },
    onUploadFile(wantedId: string) {

      const formData = new FormData();

      for (const i in Object.keys(this.selectedFiles)) {
        formData.append('imagesArray', this.selectedFiles[i]);

      }

      // sending file to the backend
      rest.POST("wanteds/uploadimages?id=" + wantedId, formData);
      const fd = new FormData();
      fd.append('imagesArray', this.profileImage);
      rest.POST("wanteds/uploadwantedprofileimage?id=" + wantedId, fd);

    },

    getRandomInt(min: number, max: number): number {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    },
    submitCreation() {
      console.log(this.color);
      if (this.profileImage == undefined) {

        return;
      }
      const wantedId = this.getRandomInt(1, 6000) + "" + this.getRandomInt(5000, 100000);
      // console.log(wantedId);
      this.onUploadFile(wantedId);
      const images = [];
      for (const i in Object.keys(this.selectedFiles)) {
        const file: any = this.selectedFiles[i];

        images.push(file.name);

      }
      rest.POST("wanteds/create", {
        wantedid: wantedId,
        label: this.label,
        text: this.text,
        price: this.price,
        gender: this.gender == 'male' ? 0 : 1,
        imagenames: images,
        profileimage: this.profileImage.name,
        dob: this.date,
        tags: this.tags,
        faction: this.faction,
        priority: this.priotiry,
        color: this.color
      }).then((data: AxiosResponse) => {
        this.$router.push("/wanteds");
      });

    }

  }

});
