
import AuthService from '@/rest';
import store from "@/store";

import {defineComponent} from 'vue';

export default defineComponent({
  name: "RegisterView",
  data() {
    return {
      username: "",
      password: "",
      token: "",
      againpassword: "",
      msg: "",
      dsgvobox: false
    }
  },
  async created() {
    if (await store.getters.isLoggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    IsButtonClickable(): boolean {


      if(!this.dsgvobox) return false;
      if(this.username == '' ||this.password == '' || this.token == '' ||this.againpassword == '') return false;
      if(this.password != this.againpassword) return false;

      return true;
    },
    async signUp() {
      try {

        if(this.username == '' ||this.password == '' || this.token == '' ||this.againpassword == ''){
          this.msg = "Bitte alle Felder ausfüllen!";
          return;
        }

        if(this.password != this.againpassword){
          this.msg = "Die Passwörter müssen übereinstimmen.";
          return;
        }
        if(!this.dsgvobox) {
          this.msg = "Du hast die Datenschutzbestimmungen noch nicht akzeptiert.";
          return;
        }
        const credentials = {
          username: this.username,
          password: this.password,
          token: this.token

        };
        // console.log(credentials);
        const response = (await AuthService.POST("account/register", credentials)).data;

        if (response.msg != "logged") {
          this.msg = response.msg;

        }else{

          const token = response.token;
          const user = response.user;
          await store.dispatch('login', {token, user});
          setTimeout(() => {
            this.msg = "Erfolgreich Registriert";
            this.$router.push('/');
          }, 500);

        }

      } catch (error: any) {
        this.msg = error.response.data.msg;
      }
    }
  }
})
