
import {defineComponent} from 'vue';

import store from "@/store";
import rest from "@/rest";
import ContactsControlComponent from "@/components/ControlPanel/ContactsControlComponent.vue";
import AccountsControlComponent from "@/components/ControlPanel/AccountsControlComponent.vue";
import TokenControlComponent from "@/components/ControlPanel/TokenControlComponent.vue";
import AlertControlComponent from "@/components/ControlPanel/AlertControlComponent.vue";
import AuctionAccessControlComponent from "@/components/ControlPanel/AuctionAccessControlComponent.vue";
import AuctionControlComponent from "@/components/ControlPanel/AuctionControlComponent.vue";
import TrainingsControlComponent from "@/components/ControlPanel/TrainingsControlComponent.vue";
import RecrutingApplysComponent from "@/components/ControlPanel/RecrutingApplysComponent.vue";
import EmployeeControlComponent from "@/components/ControlPanel/EmployeeControlComponent.vue";

export default defineComponent({
  name: 'ControlPanelView',
  components: {
    ContactsControlComponent,
    AccountsControlComponent,
    TokenControlComponent,
    AlertControlComponent,
    AuctionAccessControlComponent,
    AuctionControlComponent,
    TrainingsControlComponent,
    RecrutingApplysComponent,
    EmployeeControlComponent
  },
  data() {
    return {
      currenttab: ""
    }
  },
  async created() {
    if (!await store.getters.isLoggedIn) {
      this.$router.push("/login");
    }
  },
  methods: {
    async SetComponent(href: string) {

      if (href == "accounts" || href == "tokens" || href == "alerts" || href == "auctions") {
        if (!await store.getters.isManagerAccount) {
          alert("Zu wenige Berechtigungen!");
          return;
        }
      }
      this.currenttab = href;
    }
  }


});
