
import {defineComponent} from 'vue';
import store from "@/store";
import rest from "@/rest";
import ModalPopup from "@/components/ModalPopup.vue";

export default defineComponent({
  name: "AlertControlComponent",
  components: {
    ModalPopup,
  },
  data() {
    return {
      alerts: [],
      AlertBeingCreated: false,
      AlertCreationModelFields: [{
        name: "title",
        type: "text",
        label: "Titel",
        placeholder: "Benachrichtigung",
        needed: true
      },
        {name: "text", label: "Text", type: "text", placeholder: "Benachrichtigungstext", needed: true}, {
          name: "color",
          label: "Farbe",
          type: "color",
          value: "#021c3f",
          needed: true
        }, {
          name: "link",
          label: "Link Adresse (Optional)",
          type: "text",
          placeholder: "",
          needed: false,
          value: ""
        }],
    };
  },
  async created() {
    if (!await store.getters.isLoggedIn || !await store.getters.isManagerAccount) {
      this.$router.push("/login");
    }
    this.alerts = (await rest.GET("stats/alerts/get/all")).data;
  },
  methods: {
    async SubmitAlertModel(data: any) {
      await rest.POST("stats/alerts/create", {
        title: data.title,
        text: data.text.replace(/'/g, "\\'").replace(/"/g, '\\"'),
        color: data.color,
        link: data.link
      })

      this.closeModalAlert();

      this.alerts = (await rest.GET("stats/alerts/get/all")).data;
    },
    async closeModalAlert() {
      this.AlertBeingCreated = false;
    },
    async activateAlert(alert: any) {
      this.$confirm.require({
        message: `Willst du den Alert ${alert.title} wirklich aktivieren?`,
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {


          alert.beingActivated = true;
          if ((await rest.POST("stats/alerts/activate", {id: alert.id}))) {
            window.alert("Alert erfolgreich aktiviert!");
          } else {
            window.alert("Alert aktivierung fehlgeschlagen");
          }
          alert.beingActivated = false;
          this.alerts = (await rest.GET("stats/alerts/get/all")).data;
        },
        reject: () => {
          //
        }
      });


    },
    async deactivateAlert(alert: any) {
      this.$confirm.require({
        message: `Willst du den Alert ${alert.title} wirklich deaktivieren?`,
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: async () => {


          alert.beingDeleted = true;
          if ((await rest.POST("stats/alerts/deactivate", {id: alert.id}))) {
            window.alert("Alert erfolgreich deaktiviert!");
          } else {
            window.alert("Alert fehlgeschlagen");
          }
          alert.beingDeleted = false;
          this.alerts = (await rest.GET("stats/alerts/get/all")).data;
        },
        reject: () => {
          //
        }
      });


    },
    async CreateAlert() {
      this.AlertBeingCreated = true;
    },
    timeSince(date: DateConstructor) {

      let seconds = Math.floor(((new Date() as any) - (date as any)) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " Jahren";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " Monaten";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " Tagen";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " Stunden";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " Minuten";
      }
      return Math.floor(seconds) + " Sekunden";
    },
  }
})
