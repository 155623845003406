import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import Axios from "axios";
import VueEasyLightbox from "vue-easy-lightbox";
import Tooltip from 'primevue/tooltip';
import ConfirmationService from 'primevue/confirmationservice';
import Dialog from 'primevue/dialog';
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faList } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faList)
Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;
import ToastService from 'primevue/toastservice';

createApp(App).use(store).use(PrimeVue, {
    locale: {
        accept: 'Ja',
        reject: 'Nein'
    }
}).use(ConfirmationService).component('font-awesome-icon', FontAwesomeIcon).use(ToastService).directive('tooltip', Tooltip).use(VueEasyLightbox).use(router).mount('#app')
